.link {
    color: white;
    display: flex;
    padding: 0 10px;
    cursor: pointer;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 100%;
    white-space: normal;
    word-break: break-all;
}

.link:hover {
    color: white;
    text-decoration: unset;
    background-color: #616161;
}

.active_link {
    background-color: #616161;
}

.item {
    width: 100%;
    height: 800px;
    border: solid blue 1px;
}
